// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ElementKey, KeyValue, MixpanelApiResponse } from "./Service";
export const configJSON = require("./config");

export interface Props {
  // id: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  chartValues?: object;
  countryData: number[];

}

interface SS {
  // id: any;
}

export default class MixpanelIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCoursesApiCallId: string
  getfetchListAPICalledId: string = ""
  apiGetAccessTokenCallId: string = ""


  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      countryData: [],
      chartValues: {
        labels: ["1", "2", "3", "4", "5"],
        datasets: [
          {
            label: "Users Gained",
            data: [10, 20, 30, 40, 50],
          },
        ],
      },
    }
    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.getCoursesApiCallId = ""
  }



  getallCourses = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiendpointData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

    
    async componentDidMount() {
      this.apiCall();
      this.getallCourses()
    }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handleApiCallId(await from, await message)
    }
  


  async handleApiCallId(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getfetchListAPICalledId) {
        // TODO document why this block is empty
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiGetAccessTokenCallId) {
        let CountryList = responseJson.country
        let FilteredCountry = []
        for (const element of CountryList) {
          FilteredCountry.push(Object.values(element)[0])
        }
        this.setState({ countryData: FilteredCountry as [] });
      }
      if (responseJson && !responseJson.errors && apiRequestCallId != null && apiRequestCallId === this.getCoursesApiCallId && responseJson !== undefined) {
        this.handleResponse(responseJson, apiRequestCallId)
      }
  }
  }

  handleResponse(responseJson: MixpanelApiResponse, apiRequestCallId: String) {
    let gettingArray: { city: string, accounts: number }[] = [];
    responseJson?.country?.map((keyValue: KeyValue) => {
      for (let keyOfValue in keyValue) {
        gettingArray.push({ city: keyOfValue, accounts: keyValue[keyOfValue] });
      }
    });
    const filteredArr: string = JSON.stringify(gettingArray?.filter(
      (keyOfFilter?: { city: string, accounts: number }) => {
        if (keyOfFilter === undefined) {
          return false;
        }
        return isNaN(parseInt(keyOfFilter.city));
      }))

    let value: object[] = JSON.parse(filteredArr)
    if (this.state.chartValues === undefined){
      return this.state.chartValues;
    }
    else {
      this.setState({
        chartValues: {
          labels: value?.map((keyElement: ElementKey) => typeof (keyElement.city) === "string" ? keyElement.city : "not valid city name"),
          datasets: [
            {
              label: "user granied",
              data: value?.map((keyElement: ElementKey) => typeof (keyElement.accounts) === "number" ? keyElement.accounts : 0),
            }],
        }
      })
    }
  }


  doButtonPressed() {
    let eventMsg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    eventMsg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(eventMsg);
  }
  apiCall = async () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAccessTokenCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        // "token": token,
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiendpointData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };






}

// Customizable Area End
