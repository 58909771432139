// Customizable Area Start
import React from "react";

// import { Bar } from "react-chartjs-2";

import MixpanelIntegrationController, {
  Props
} from "./MixpanelIntegrationController";

export default class MixpanelIntegration extends MixpanelIntegrationController {
  constructor(props: Props) {
    super(props);
  }

  // new

  render() {
    return (
      <div style={{ width: 1300 }}>
        {/* {this.state.chartValues === undefined ? (
          ""
        ) : (
          <Bar data={this.state.chartValues} />
        )} */}
      </div>
    );
  }
}


// Customizable Area End
