Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DeliveryEstimator";
exports.labelBodyText = "DeliveryEstimator Body";
exports.labelButtonText1="On Going"
exports.labelButtonText2="Completed"
exports.labelTitleText1="Order Id"
exports.labelTitleText2="Order Date"
exports.labelTitleText3="Order Delivered"
exports.labelTitleText4="Qty"
exports.labelTitleText5="STATUS"
exports.labelTitleText6="Partner"
exports.btnExampleTitle = "CLICK ME";

exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"
  
exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "order_management/orders";
exports.rateAPiEndPoint = "catalogue/reviews";
exports.orderStatusApiEndPoint="current_status";
// Customizable Area End