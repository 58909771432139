import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Switch,
  Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import FeatureSettingsController, {
  Props,
  configJSON,
} from "./FeatureSettingsController";

export default class FeatureSettings extends FeatureSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  logoutModal = () => {
    return(
      <Modal
        aria-labelledby="simple-modal-title"
        open={this.state.logoutModal}
        onClose={this.closeLogoutModal }
      >
        <>
        <div style={webStyle.modalStyle}>
        <Typography style={webStyle.modalText}>
          Are you sure, you want to Logout?
        </Typography>
        <div style={{ width: "100%",flexDirection: "row", paddingLeft: '30px'}}>
        <Box
          data-test-id="modalYesButton"
          onClick={this.logout}
          component="button"
          sx={webStyle.modalYesButtonStyle}
        >
          <Button color={"primary"}>{configJSON.yesBtnText}</Button>
        </Box>
        <Box
          data-test-id="modalNoButton"
          onClick={this.closeLogoutModal}
          component="button"
          sx={webStyle.modalNoButtonStyle}
        >
          <Button color={"primary"}>{configJSON.noBtnText}</Button>
        </Box>
        </div>
        </div>
       
        </>
      </Modal>
    )
  }

  delAccModal = () => {
    return(
      <Modal
        aria-labelledby="simple-modal-title"
        open={this.state.delAccModal}
        onClose={this.closeDelAccModal}
      >
        <>
        <div style={webStyle.modalStyle}>
        <Typography style={webStyle.modalText}>
          Are you sure, you want to Delete?
        </Typography>
        <div style={{ width: "100%",flexDirection: "row", paddingLeft: '30px'}}>
        <Box
          data-test-id="modalYesButton"
          onClick={this.deleteAccount}
          component="button"
          sx={webStyle.modalYesButtonStyle}
        >
          <Button color={"primary"}>{configJSON.yesBtnText}</Button>
        </Box>
        <Box
          data-test-id="modalNoButton"
          onClick={this.closeDelAccModal}
          component="button"
          sx={webStyle.modalNoButtonStyle}
        >
          <Button color={"primary"}>{configJSON.noBtnText}</Button>
        </Box>
        </div>
        </div>
       
        </>
      </Modal>
    )
  }
  confirmPass = () => {
    return (
      <>
       <Box sx={webStyle.inputStyle}>
              <Input
                data-test-id={"newPasswordInput"}
                type={this.state.newPasswordField ? "password" : "text"}
                placeholder={configJSON.newPasswordInputPlaceholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.newPasswordInput}
                onChange={(event) => this.newPasswordInputMobileProps.onChangeText(event.target.value)}
                endAdornment={
                  <InputAdornment position="end" >
                    <IconButton
                      aria-label="toggle password visibility"
                      data-test-id={"newPasswordShowBtn"}
                      onClick={() => this.setNewPasswordField() }
                      edge="end"
                    >
                      {this.state.newPasswordField ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>

            <Box sx={webStyle.inputStyle}>
                <Input
                    data-test-id={"passwordInput"}
                    type={this.state.passwordField ? "password" : "text"}
                    placeholder="Confirm Password"
                    fullWidth={true}
                    disableUnderline={true}
                    value={this.state.passwordInput}
                    onChange={(event) =>  this.passwordInputMobileProps.onChangeText(event.target.value)}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        data-test-id={"passwordShowBtn"}
                        onClick={()=> this.setPasswordField() }
                        edge="end"
                        >
                        {this.state.passwordField ? (
                            <Visibility />
                        ) : (
                            <VisibilityOff />
                        )}
                        </IconButton>
                    </InputAdornment>
                    }
                />
            </Box>

            <Box
                data-test-id="changePasswordButton"
                onClick={this.openChangePassModal}
                component="button"
                sx={webStyle.buttonStyle1}
            >
                <Button color={"primary"}>{configJSON.changePasswordBtnText}</Button>
            </Box>

      </>
    )
  }

  changePassModal = () => {
    return(
      <Modal
        aria-labelledby="simple-modal-title"
        open={this.state.changePassModal}
        onClose={this.closeChangePassModal}
      >
        <>
        <div style={webStyle.modalStyle}>
        <Typography style={webStyle.modalText}>
          Are you sure, you want to Change Password?
        </Typography>
        <div style={{ width: "100%",flexDirection: "row", paddingLeft: '30px'}}>
        <Box
          data-test-id="modalYesButton"
          onClick={this.changePassword}
          component="button"
          sx={webStyle.modalYesButtonStyle}
        >
          <Button color={"primary"}>{configJSON.yesBtnText}</Button>
        </Box>
        <Box
          data-test-id="modalNoButton"
          onClick={this.closeChangePassModal}
          component="button"
          sx={webStyle.modalNoButtonStyle}
        >
          <Button color={"primary"}>{configJSON.noBtnText}</Button>
        </Box>
        </div>
        </div>
       
        </>
      </Modal>
    )
  }
  // Customizable Area End

  render() {
    const { newPasswordField, passwordField} = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            {this.logoutModal()}
            {this.delAccModal()}
            {this.changePassModal()}
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div" data-test-id='labelBody'>
              {configJSON.labelBodyText}
            </Typography>
            <Box
                data-test-id="changePassShowhideBtn"
                onClick={this.onChangePassClicked }
                component="button"
                sx={webStyle.buttonStyle}
            >
                <Button color={"primary"}>{configJSON.changePasswordBtnText}</Button>
            </Box>
            {this.state.showChangePass === true ? this.confirmPass(): null}
            <Box sx={webStyle.hr}/>
              
              <Box sx={webStyle.deleteAccountContainer}>
              <Typography style={webStyle.deleteText}>
                {configJSON.notificationPreferenceBtnText}
              </Typography>
              <Switch 
              checked={this.state.isEnabled}
              onChange={this.toggleSwitch}
              />
            </Box>
           
              <Box sx={webStyle.hr}/>
            <Box sx={webStyle.deleteAccountContainer}>
              <Typography style={webStyle.deleteText}>
                {configJSON.deleteAccountText}
              </Typography>
                <Box
                    data-test-id="deleteButton"
                    onClick={this.openDelAccModal}
                    component="button"
                    sx={webStyle.buttonStyle}
                >
                    <Button color={"primary"}>{configJSON.deleteAccountButtonText}</Button>
                </Box>
            </Box>
            <Box sx={webStyle.hr}/>

            <Box
              data-test-id="logoutButton"
              onClick={this.openLogoutModal}
              component="button"
              sx={webStyle.buttonStyle}
            >
              <Button color={"primary"}>{configJSON.logoutButtonText}</Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "80px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
    paddingBottom: "0px",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    borderRadius: "5px",
  },
  buttonStyle1: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "#858585",
    borderRadius: "5px",
  },
  deleteAccountContainer: {
    display: "flex",
    flexDirection: 'row',
    marginTop: "16px",
    justifyContent: 'space-between',
    alignItems: "center",
    width: "100%",
  },
  deleteText :{
    marginTop: "25px",
    alignItems: "center",
    width: "50%",
  },
  modalStyle: {
    background: "#fff",
    width: "35%",
    height: "23%",
    borderRadius: "15px",
    marginTop: "20%",
    marginLeft: "35%",
    padding: "3px"
  },
  modalText :{
    alignItems: "center",
    fontSize: "23px",
    width: "100%",
    // textAlign: "center"
  },
  modalYesButtonStyle: {
    marginTop: "45px",
    width: "40%",
    height: "45px",
    border: "none",
    backgroundColor: "green",
    borderRadius: "5px",
  },
  modalNoButtonStyle: {
    marginLeft: "10px",
    marginTop: "45px",
    width: "40%",
    height: "45px",
    border: "none",
    backgroundColor: "red",
    borderRadius: "5px",
  },
  hr :{
    height: '2px',
    backgroundColor: 'grey',
    width: '100%',
    marginTop: "15px",
  }
};
// Customizable Area End
