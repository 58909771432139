// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import FeatureSettings from "../../blocks/featuresettings/src/FeatureSettings";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Newsfeed from "../../blocks/newsfeed/src/Newsfeed";
import Shippingoptions from "../../blocks/shippingoptions/src/Shippingoptions";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Quickbooksintegration from "../../blocks/quickbooksintegration/src/Quickbooksintegration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Collecttransactionfees from "../../blocks/collecttransactionfees/src/Collecttransactionfees";
import ContentFlag from "../../blocks/contentflag/src/ContentFlag";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Storelocator2 from "../../blocks/storelocator2/src/Storelocator2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Location from "../../blocks/location/src/Location";
import MixpanelIntegration from "../../blocks/mixpanelintegration/src/MixpanelIntegration";
import Cfsubcartsforshoppingcart2 from "../../blocks/cfsubcartsforshoppingcart2/src/Cfsubcartsforshoppingcart2";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfexchangeablepointsrewardssystem2 from "../../blocks/cfexchangeablepointsrewardssystem2/src/Cfexchangeablepointsrewardssystem2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Communityforum3 from "../../blocks/communityforum3/src/Communityforum3";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Share from "../../blocks/share/src/Share";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Followers from "../../blocks/followers/src/Followers";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Sorting from "../../blocks/sorting/src/Sorting";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import DeliveryEstimator from "../../blocks/deliveryestimator/src/DeliveryEstimator";
import Bitcoinpayment from "../../blocks/bitcoinpayment/src/Bitcoinpayment";
import Conversationthreading2 from "../../blocks/conversationthreading2/src/Conversationthreading2";
import Search from "../../blocks/search/src/Search";
import Wishlist2 from "../../blocks/wishlist2/src/Wishlist2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Donationpayments from "../../blocks/donationpayments/src/Donationpayments";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Contentmoderation from "../../blocks/contentmoderation/src/Contentmoderation";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import LoyaltySystem from "../../blocks/loyaltysystem/src/LoyaltySystem";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Maps from "../../blocks/maps/src/Maps";



const routeMap = {
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
FeatureSettings:{
 component:FeatureSettings,
path:"/FeatureSettings"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Newsfeed:{
 component:Newsfeed,
path:"/Newsfeed"},
Shippingoptions:{
 component:Shippingoptions,
path:"/Shippingoptions"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Quickbooksintegration:{
 component:Quickbooksintegration,
path:"/Quickbooksintegration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Collecttransactionfees:{
 component:Collecttransactionfees,
path:"/Collecttransactionfees"},
ContentFlag:{
 component:ContentFlag,
path:"/ContentFlag"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Storelocator2:{
 component:Storelocator2,
path:"/Storelocator2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Location:{
 component:Location,
path:"/Location"},
MixpanelIntegration:{
 component:MixpanelIntegration,
path:"/MixpanelIntegration"},
Cfsubcartsforshoppingcart2:{
 component:Cfsubcartsforshoppingcart2,
path:"/Cfsubcartsforshoppingcart2"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Cfexchangeablepointsrewardssystem2:{
 component:Cfexchangeablepointsrewardssystem2,
path:"/Cfexchangeablepointsrewardssystem2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Communityforum3:{
 component:Communityforum3,
path:"/Communityforum3"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Share:{
 component:Share,
path:"/Share"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Followers:{
 component:Followers,
path:"/Followers"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
DeliveryEstimator:{
 component:DeliveryEstimator,
path:"/DeliveryEstimator"},
Bitcoinpayment:{
 component:Bitcoinpayment,
path:"/Bitcoinpayment"},
Conversationthreading2:{
 component:Conversationthreading2,
path:"/Conversationthreading2"},
Search:{
 component:Search,
path:"/Search"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Donationpayments:{
 component:Donationpayments,
path:"/Donationpayments"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Contentmoderation:{
 component:Contentmoderation,
path:"/Contentmoderation"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Maps:{
 component:Maps,
path:"/Maps"},

  Home: {
component:TermsConditions,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
