// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MixpanelIntegration";
exports.labelBodyText = "MixpanelIntegration Body";
exports.apiendpointData= "/bx_block_mixpanel_integration/mixpanel_integrations/country_base_accounts"

exports.btnExampleTitle = "CLICK ME";

// Customizable Area End
